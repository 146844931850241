<template>
    <div class="page">
        <app-header :navIndex="3"></app-header>
        <banner :data="banner"></banner>
        <section class="top-box">
            <div class="top-border hidden-md-and-up white"></div>
            <!--<div class="section-container pt-md-12 pt-sm-0">
                <div class="banner pl-4 pr-4 pl-md-0 pr-md-0 mb-4 mb-md-0">
                    <v-img class="showImg" :src="otherBanner.img"></v-img>
                    <div class="banner-title white&#45;&#45;text text-center">
                        {{otherBanner.title}}<br>{{otherBanner.enTitle}}
                    </div>
                </div>
            </div>-->
            <section
                    class="section-container d-flex flex-column flex-md-row justify-center justify-md-space-between img-box">
                <div class="img-lt">
                    <v-img contain :src="typeList.img"></v-img>
                </div>
                <div class="d-flex flex-column justify-md-space-between">
                    <div class="img-rt mb-4 mb-md-0" v-for="(item,index) in typeList.items">
                        <v-img contain :src="item.img"></v-img>
                        <base-btn class="more-btn" @click="enter(item.to)">{{item.title}}
                            <span class="iconfont iconjiantou"></span>
                        </base-btn>
                    </div>
                </div>
            </section>
        </section>

        <!--公司福利-->
        <section class="section-container mt-12" v-if="false">
            <section-title :title="welfare.title" :enTitle="welfare.enTitle"></section-title>
            <div class="d-flex flex-column flex-md-row justify-space-between mt-3">
                <v-hover class="mb-4 mb-md-0" v-slot:default="{hover}" v-for="(item,index) in welfare.items">
                    <v-card class="inner-box align-center" :elevation="hover ? 6 : 1"
                            :class="{ 'on-hover': hover }">
                        <div class="ml-12 mr-12 mt-6 mt-md-12">
                            <v-img :src="item.img"></v-img>
                        </div>
                        <div class="inner-title text-center mt-6">{{item.title}}</div>
                        <div class="sub-title text-center">{{item.subTitle}}</div>
                    </v-card>
                </v-hover>
            </div>
        </section>
        <!--公司文化-->
        <section class="section-container mt-12" v-if="false">
            <section-title :title="culture.title" :enTitle="culture.enTitle"></section-title>
            <div class="d-flex flex-md-row align-center justify-center">
                <div class="type ma-4 ma-md-12 pb-1" v-for="(item,index) in culture.typeList"
                     :class="item.id==activeIndex?'selected-type':''" @click="activeIndex=item.id">{{item.title}}
                </div>
            </div>
            <div class="culture-img-box">
                <waterfall :list="culture.imgList" :gutter="10"
                           :breakpoints="{
                                1200: { //当屏幕宽度小于等于1200
                                  rowPerView: 3,
                                },
                                800: { //当屏幕宽度小于等于800
                                  rowPerView: 2,
                                },
                                500: { //当屏幕宽度小于等于500
                                  rowPerView: 2,
                                }
                              }"
                           ref="waterfall">
                    <template slot="item" slot-scope="props">
                        <v-hover class="culture-img" v-slot:default="{hover}">
                            <v-card :elevation="hover ? 6 : 0"
                                    :class="{ 'on-hover': hover }">
                                <v-img width="100%" :src="props.data.img" @load="$refs.waterfall.refresh()"></v-img>
                                <v-expand-transition>
                                    <div v-if="hover"
                                         class="d-flex transition-fast-in-fast-out v-card--reveal white--text">
                                        {{props.data.title}}
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-hover>
                    </template>
                </waterfall>
            </div>
        </section>
    </div>
</template>
<script>
    import Waterfall from 'vue-waterfall-plugin'
    import Banner from "../../components/base/banner";
    import SectionTitle from "../company/components/section-title";
    import BaseBtn from "../../components/base/Btn";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            BaseBtn,
            Waterfall,
            SectionTitle,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'employee/join/bg.jpg',
                    title: this.$i18n.t('others.employee.join.title'),
                    enTitle: ''
                },
                typeList: {
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/top_one.jpg'),
                    title: '',
                    items: [{
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/top_two.jpg'),
                        to: '/employee/join/school',
                        title: this.$i18n.t('others.employee.join.schoolTitle'),
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/top_three.jpg'),
                        to: '/employee/join/social',
                        title: this.$i18n.t('others.employee.join.socialTitle'),
                    }]
                },
                otherBanner: {
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/bg_two.jpg'),
                    title:this.$i18n.t('others.employee.join.otherBanner.title'),
                    enTitle: this.$i18n.t('others.employee.join.otherBanner.enTitle'),
                },
                welfare: {
                    title: '我们的福利',
                    enTitle: 'COMPANY WELFARE',
                    items: [{
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/img_one.jpg'),
                        title: '贴心的福利',
                        subTitle: '年度健康体检·年度旅游·员工假期'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/img_two.jpg'),
                        title: '完善的保障',
                        subTitle: '社会与商业保险·住房公积金'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/img_three.jpg'),
                        title: '合理的薪酬',
                        subTitle: '固定薪资·绩效奖金·年终奖金'
                    }]
                },
                culture: {
                    title: '公司文化',
                    enTitle: 'CORPORATE CULTURE',
                    typeList: [{id: 1, title: '办公环境'}, {id: 2, title: '员工生活'}],
                    imgList: [{
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_one.jpg'),
                        title: '客厅'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_two.jpg'),
                        title: '会议室'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_three.jpg'),
                        title: '餐厅'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_five.jpg'),
                        title: '餐厅'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_four.jpg'),
                        title: '餐厅'
                    }, {
                        img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/pic_six.jpg'),
                        title: '会议室'
                    }]
                },
                activeIndex: 1
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "加入我们"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            enter(url){
                this.$router.push(url)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .img-box {
        height: 520px;
        margin: 50px auto 80px;
        .img-lt {
            width: 730px;
            height: 100%;
        }
        .img-rt {
            width: 460px;
            height: 258px;
            position: relative;
            .more-btn {
                @include ct();
                width: 160px;
                @media (max-width: $screen-sm) {
                    width: 80px;
                    height: 24px !important;
                    font-size: 10px;
                }
            }
        }
    }

    .banner {
        width: 100%;
        position: relative;
        .banner-title {
            @include ct();
            width: 100%;
            line-height: 40px;
            font-size: 30px;
            @media (max-width: $screen-sm) {
                line-height: 14px;
                font-size: 10px;
            }
        }
    }

    .inner-box {
        width: 380px;
        height: 400px;
        .inner-title {
            font-size: 20px;
            color: $primary-color;
        }
        .sub-title {
            font-size: 16px;
        }
    }

    .type {
        font-size: 18px;
        color: #9F9F9F;
        border-bottom: 1px solid transparent;
        cursor: pointer;
    }

    .selected-type {
        color: $primary-color;
        border-bottom: 1px solid $primary-color;
    }

    .culture-img-box {
        width: 100%;
        margin-bottom: 80px;
        .culture-img {
            .v-card--reveal {
                height: 100%;
                background-color: $primary-color;
                align-items: center;
                bottom: 0;
                justify-content: center;
                opacity: .9;
                position: absolute;
                width: 100%;
                font-size: 20px;
            }
        }
    }

    @media (max-width: $screen-md) {
        .img-box {
            margin: 0;
            height: auto !important;
            .img-lt {
                display: none;
            }
            .img-rt {
                width: 92% !important;
                height: auto !important;
                margin: auto;
            }
        }
        .type {
            font-size: 16px;
        }
        .inner-box {
            width: 92% !important;
            height: auto !important;
            padding-bottom: 20px;
            margin: auto;
            .inner-title {
                font-size: 16px;
            }
            .sub-title {
                font-size: 10px;
            }
        }
        .culture-img-box {
            margin-bottom: 70px;
            .culture-img {
                .v-card--reveal {
                    font-size: 14px;
                }
            }
        }
    }

    @media (max-width: $screen-sm) {
        .type {
            font-size: 14px;
        }
        .culture-img-box {
            .culture-img {
                .v-card--reveal {
                    font-size: 12px;
                }
            }
        }
    }

</style>
