<template>
    <div class="section-title mb-5">
        <div class="section-title text-center">{{title}}</div>
        <div class="en-title mt-n3 mt-md-n9 text-center">{{enTitle}}</div>
    </div>
</template>

<script>
    export default {
        name: "section-title",
        data() {
            return {}
        },
        props: ['title', 'enTitle']
    }
</script>

<style lang="scss" scoped>
    .section-title {
        .section-title {
            font-size: 30px;
            color: $primary-color;
        }
        .en-title {
            font-size: 40px;
            color: rgba(159, 159, 159, 0.10);
            font-family: DINAlternate-Bold, DINAlternate;
        }
        @media (max-width: $screen-md) {
            .section-title {
                font-size: 20px;
            }
            .en-title {
                font-size: 20px;
            }
        }
        @media (max-width: $screen-sm) {
            .section-title {
                font-size: 18px;
            }
            .en-title {
                font-size: 18px;
            }
        }
    }

</style>
