var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('app-header',{attrs:{"navIndex":3}}),_c('banner',{attrs:{"data":_vm.banner}}),_c('section',{staticClass:"top-box"},[_c('div',{staticClass:"top-border hidden-md-and-up white"}),_c('section',{staticClass:"section-container d-flex flex-column flex-md-row justify-center justify-md-space-between img-box"},[_c('div',{staticClass:"img-lt"},[_c('v-img',{attrs:{"contain":"","src":_vm.typeList.img}})],1),_c('div',{staticClass:"d-flex flex-column justify-md-space-between"},_vm._l((_vm.typeList.items),function(item,index){return _c('div',{staticClass:"img-rt mb-4 mb-md-0"},[_c('v-img',{attrs:{"contain":"","src":item.img}}),_c('base-btn',{staticClass:"more-btn",on:{"click":function($event){return _vm.enter(item.to)}}},[_vm._v(_vm._s(item.title)+" "),_c('span',{staticClass:"iconfont iconjiantou"})])],1)}),0)])]),(false)?_c('section',{staticClass:"section-container mt-12"},[_c('section-title',{attrs:{"title":_vm.welfare.title,"enTitle":_vm.welfare.enTitle}}),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between mt-3"},_vm._l((_vm.welfare.items),function(item,index){return _c('v-hover',{staticClass:"mb-4 mb-md-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"inner-box align-center",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 1}},[_c('div',{staticClass:"ml-12 mr-12 mt-6 mt-md-12"},[_c('v-img',{attrs:{"src":item.img}})],1),_c('div',{staticClass:"inner-title text-center mt-6"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"sub-title text-center"},[_vm._v(_vm._s(item.subTitle))])])]}}],null,true)})}),1)],1):_vm._e(),(false)?_c('section',{staticClass:"section-container mt-12"},[_c('section-title',{attrs:{"title":_vm.culture.title,"enTitle":_vm.culture.enTitle}}),_c('div',{staticClass:"d-flex flex-md-row align-center justify-center"},_vm._l((_vm.culture.typeList),function(item,index){return _c('div',{staticClass:"type ma-4 ma-md-12 pb-1",class:item.id==_vm.activeIndex?'selected-type':'',on:{"click":function($event){_vm.activeIndex=item.id}}},[_vm._v(_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"culture-img-box"},[_c('waterfall',{ref:"waterfall",attrs:{"list":_vm.culture.imgList,"gutter":10,"breakpoints":{
                            1200: { //当屏幕宽度小于等于1200
                              rowPerView: 3,
                            },
                            800: { //当屏幕宽度小于等于800
                              rowPerView: 2,
                            },
                            500: { //当屏幕宽度小于等于500
                              rowPerView: 2,
                            }
                          }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-hover',{staticClass:"culture-img",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0}},[_c('v-img',{attrs:{"width":"100%","src":props.data.img},on:{"load":function($event){return _vm.$refs.waterfall.refresh()}}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal white--text"},[_vm._v(" "+_vm._s(props.data.title)+" ")]):_vm._e()])],1)]}}],null,true)})]}}],null,false,1059546309)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }